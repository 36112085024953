<template>
    <p>Recepcion</p>
 </template>
 
 <script> 
 
 export default {
   components: {
    
   },
 }
 </script>
 